import { TableColumnsType } from "antd";
import { TableCustom, TagViewDetailColumn } from "@components/table";
import { globalModalState, salonActiveState, salonsState } from "@components/state";
import { useRecoilState } from "recoil";
import { OverviewRevenueDetailTable } from "./table-details/OverviewRevenueDetailTable";
import { UseQueryResult } from "@tanstack/react-query";
import { GetRevenueOverviewResponse } from "@/api";
import { revenueReportUtils } from "../utils";
import { useCallback, useMemo } from "react";

export interface OverViewRevenueData {
    readonly id?: string;
    readonly title?: string;
    readonly objectType?: string;
    readonly value?: number | string;
}

interface OverViewRevenueTableProps {
    readonly revenueOverview: UseQueryResult<GetRevenueOverviewResponse, Error>[]
}

export function OverViewRevenueTable(props: OverViewRevenueTableProps) {
    const { revenueOverview } = props;
    const [salonActive] = useRecoilState(salonActiveState);
    const [salons] = useRecoilState(salonsState);
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const revenueOverviewData = revenueOverview?.map((revenue) => revenue.data);
	const isLoading = revenueOverview?.some((revenue) => revenue.isLoading);

    const getChildrenColumnTable = useCallback((objectType: string) => {
        const isGetTotalInvoice = objectType === "TOTAL_INVOICE";
        const objectTypeGet = isGetTotalInvoice ? "ALL" : objectType;

        const children = revenueOverviewData?.filter((revenue) => revenue?.objectType === objectTypeGet).map((revenueSalon) => {
            let nameItem = salons?.find((salon) => salon.id === revenueSalon?.salonId)?.name;

            if(salonActive) {
                nameItem = salonActive?.salonBranches?.find((branch) => branch.id === revenueSalon?.salonBranchId)?.name
            }

            return {
                title: nameItem,
                value: (isGetTotalInvoice ? revenueSalon?.totalInvoice : revenueSalon?.totalRevenue) ?? 0
            }
        })

        return children;
    }, [revenueOverviewData, salonActive, salons]);

    const showDetail = useCallback((objectType?: string, title?: string) => {
        const dataTable = getChildrenColumnTable(objectType!);

        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: title,
            width: 850,
            content: <OverviewRevenueDetailTable  dataTable={dataTable}/>
        });
    }, [getChildrenColumnTable, globalModal, setGlobalModal]);

    const data = useMemo<OverViewRevenueData[]>(
		() => [
			{
				objectType: 'TOTAL_INVOICE',
				value:
					revenueReportUtils.getTotalRevenue(revenueOverview, 'ALL')?.totalInvoice ?? 0,
				title: 'Tổng số hoá đơn',
			},
			{
				objectType: 'ALL',
				value:
					revenueReportUtils
						.getTotalRevenue(revenueOverview, 'ALL')
						?.totalRevenue?.toLocaleString('en-US') ?? 0,
				title: 'Tổng doanh thu',
			},
			{
				objectType: 'BOOKING',
				value:
					revenueReportUtils
						.getTotalRevenue(revenueOverview, 'BOOKING')
						?.totalRevenue.toLocaleString('en-US') ?? 0,
				title: 'Tổng doanh thu lịch hẹn',
			},
			{
				objectType: 'SERVICE',
				value:
					revenueReportUtils
						.getTotalRevenue(revenueOverview, 'SERVICE')
						?.totalRevenue?.toLocaleString('en-US') ?? 0,
				title: 'Tổng doanh thu dịch vụ',
			},
			{
				objectType: 'PRODUCT',
				value:
					revenueReportUtils
						.getTotalRevenue(revenueOverview, 'PRODUCT')
						?.totalRevenue?.toLocaleString('en-US') ?? 0,
				title: 'Tổng doanh thu sản phẩm',
			},
			{
				objectType: 'PACKAGE',
				value:
					revenueReportUtils
						.getTotalRevenue(revenueOverview, 'PACKAGE')
						?.totalRevenue?.toLocaleString('en-US') ?? 0,
				title: 'Tổng doanh thu gói dịch vụ',
			},
			{
				objectType: 'CASH_CARD',
				value:
					revenueReportUtils
						.getTotalRevenue(revenueOverview, 'CASH_CARD')
						?.totalRevenue?.toLocaleString('en-US') ?? 0,
				title: 'Tổng doanh thu thẻ tiền mặt',
			},
			{
				objectType: 'DEBIT',
				value:
					revenueReportUtils
						.getTotalRevenue(revenueOverview, 'DEBIT')
						?.totalRevenue?.toLocaleString('en-US') ?? 0,
				title: 'Tổng công nợ',
			},
			{
				objectType: 'DISCOUNT',
				value:
					revenueReportUtils
						.getTotalRevenue(revenueOverview, 'DISCOUNT')
						?.totalRevenue?.toLocaleString('en-US') ?? 0,
				title: 'Tổng giảm giá',
			},
			{
				objectType: 'ALL',
				value:
					(
						(revenueReportUtils.getTotalRevenue(revenueOverview, 'ALL')?.totalRevenue ??
							0) -
						(revenueReportUtils.getTotalRevenue(revenueOverview, 'DEBIT')
							?.totalRevenue ?? 0)
					)?.toLocaleString('en-US') ?? 0,
				title: 'Tổng thanh toán',
			},
		],
		[revenueOverview]
	);

    const columns = useMemo<TableColumnsType<OverViewRevenueData>>(
		() => [
			{
				title: 'Tiêu đề',
				dataIndex: 'title',
				key: 'title',
			},
			{
				title: 'Giá trị',
				dataIndex: 'value',
				key: 'value',
				width: 150,
			},
			{
				title: 'Liên kết',
				width: 150,
				render: (_, record) => {
					return (
						<TagViewDetailColumn
							showDetail={() => showDetail(record.objectType, record.title)}
						/>
					);
				},
			},
		],
		[showDetail]
	);

    return (
        <TableCustom
			loading={isLoading}
            scroll={{ x: 800 }}
            placeholderSearchTransCode='Search ID, Client, Phone, Email'
            showHeader={false}
            columns={columns}
            dataSource={data}
        />
    )
}
