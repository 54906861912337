import { TableColumnsType } from "antd";
import { TableCustom, TagViewDetailColumn } from "@components/table";
import { globalModalState, salonActiveState, salonsState } from "@components/state";
import { useRecoilState } from "recoil";
import { OverviewBookingDetailData, OverviewBookingDetailTable } from "./table-detail/OverviewBookingDetailTable";
import { useQueriesBookingSummary, useQueriesBookingTotalNewCustomer } from "../hooks";
import { Dayjs } from "dayjs";
import { useHttpQueries } from "@/hooks";
import { GetBookingSummaryResponse, GetBookingTotalNewCustomerResponse } from "@/api";
import { useCallback, useMemo } from "react";

export interface OverviewBookingData {
    readonly id?: string;
    readonly title?: string;
    readonly value?: number | string;
    readonly dataDetails?: OverviewBookingDetailData[];
}

interface OverviewBookingTableProps {
    dateRange: [Dayjs, Dayjs];
}

export function OverviewBookingTable(props: OverviewBookingTableProps) {
    const { dateRange } = props;

    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const [salonActive] = useRecoilState(salonActiveState);
    const [salons] = useRecoilState(salonsState);

    const queriesBookingSummary = useQueriesBookingSummary(dateRange);
    const queriesBookingTotalNewCustomer = useQueriesBookingTotalNewCustomer(dateRange);

    const bookingSummaryResult = useHttpQueries(queriesBookingSummary);
    const bookingTotalNewCustomerResult = useHttpQueries(queriesBookingTotalNewCustomer);

    const bookingSummaryData = useMemo(() => 
        bookingSummaryResult.map((item) => item.data) as GetBookingSummaryResponse[],
    [bookingSummaryResult]);
    
    const bookingTotalNewCustomerData = useMemo(() => 
        bookingTotalNewCustomerResult.map((item) => item.data) as GetBookingTotalNewCustomerResponse[],
    [bookingTotalNewCustomerResult]);

    const isLoading = useMemo(() => {
        return bookingSummaryResult.some((booking) => booking.isLoading) || bookingTotalNewCustomerResult.some((booking) => booking.isLoading);
    }, [bookingSummaryResult, bookingTotalNewCustomerResult]);

    const showDetail = useCallback((title: string, dataTable: OverviewBookingDetailData[]) => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: title,
            width: 850,
            content: <OverviewBookingDetailTable dataTable={dataTable} />
        });
    }, [globalModal, setGlobalModal]);

    const getChildrenTableData = useCallback((
        data: (GetBookingSummaryResponse | GetBookingTotalNewCustomerResponse)[],
        field: keyof GetBookingSummaryResponse | 'totalNewCustomers'
    ) => {
        return data.map((item) => {
            const nameItem = salonActive
                ? salonActive.salonBranches?.find((branch) => branch.id === item?.salonBranchId)?.name
                : salons?.find((salon) => salon.id === item?.salonId)?.name;

            const value = (field === 'totalNewCustomers' 
                ? (item as GetBookingTotalNewCustomerResponse)?.totalNewCustomers 
                : (item as GetBookingSummaryResponse)?.[field]) ?? 0;

            return {
                value: value,
                title: nameItem
            };
        }) as OverviewBookingDetailData[];
    }, [salonActive, salons]);

    const data: OverviewBookingData[] = useMemo(() => [
        {
            id: '1',
            value: bookingSummaryData?.reduce((acc, curr) => acc + (curr?.totalBooking ?? 0), 0),
            title: "Tổng số lịch hẹn",
            dataDetails: getChildrenTableData(bookingSummaryData, 'totalBooking')
        },
        {
            id: '2',
            value: bookingSummaryData?.reduce((acc, curr) => acc + (curr?.totalServices ?? 0), 0),
            title: "Tổng dịch vụ",
            dataDetails: getChildrenTableData(bookingSummaryData, 'totalServices')
        },
        {
            id: '3',
            value: bookingSummaryData?.reduce((acc, curr) => acc + (curr?.totalCustomers ?? 0), 0),
            title: "Số khách hàng đặt lịch",
            dataDetails: getChildrenTableData(bookingSummaryData, 'totalCustomers')
        },
        {
            id: '5',
            value: bookingSummaryData?.reduce((acc, curr) => acc + (curr?.totalBookingNotCome ?? 0), 0),
            title: "Khách không đến",
            dataDetails: getChildrenTableData(bookingSummaryData, 'totalBookingNotCome')
        },
        {
            id: '6',
            value: bookingTotalNewCustomerData?.reduce((acc, curr) => acc + (curr?.totalNewCustomers ?? 0), 0),
            title: "Khách mới",
            dataDetails: getChildrenTableData(bookingTotalNewCustomerData, 'totalNewCustomers')
        },
        {
            id: '7',
            value: bookingSummaryData?.reduce((acc, curr) => acc + (curr?.totalRevenueBooking ?? 0), 0),
            title: "Tổng doanh thu",
            dataDetails: getChildrenTableData(bookingSummaryData, 'totalRevenueBooking')
        }
    ], [bookingSummaryData, bookingTotalNewCustomerData, getChildrenTableData]);

    const columns: TableColumnsType<OverviewBookingData> = useMemo(() => [
        {
            title: 'Tiêu đề',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Giá trị',
            dataIndex: 'value',
            key: 'value',
            render: (value: number) => value?.toLocaleString('en-US'),
            width: 150
        },
        {
            title: "Liên kết",
            width: 150,
            render: (_, record) => {
                return (
                    <TagViewDetailColumn showDetail={() => showDetail(record.title ?? '', record.dataDetails ?? [])} />
                );
            },
        },
    ], [showDetail]);

    return (
        <TableCustom
            loading={isLoading}
            scroll={{ x: 800 }}
            showHeader={false}
            columns={columns}
            dataSource={data}
        />
    )
}
