import { Dayjs } from 'dayjs';
import { atom } from 'recoil';

export interface AccountInfo {
    readonly id: string;
    readonly email?: string;
    readonly salons: Salon[];
}
export interface Salon {
	readonly id: number;
	readonly photoId: number;
	readonly name?: string;
	readonly url?: string;
	readonly path?: string;
	readonly email?: string;
	readonly created?: Dayjs;
	readonly address?: string;
	readonly isDivideCustomerByBranch: 'true' | 'false';
	readonly salonBranches?: SalonBranch[];
}
export interface SalonBranch {
    readonly id: number;
    readonly name?: string;
    readonly address?: string;
    readonly salonId?: number;
    readonly state?: string;
    readonly create?: Dayjs;
}

export const accountInfoState = atom<AccountInfo | null>({
    key: 'ACCOUNT_INFO',
    default: null
});

export const salonActiveState = atom<Salon | null>({
    key: 'SALON_ACTIVE',
    default: null
});

export const salonsState = atom<Salon[] | null>({
    key: 'SALONS',
    default: null
});
