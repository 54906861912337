import { getBookingTotalNewCustomer } from "@/api/report";
import { salonActiveState, salonsState } from "@/components/state";
import { formatDateTime } from "@/utils";
import { Dayjs } from "dayjs";
import { useRecoilState } from "recoil";

export function useQueriesBookingTotalNewCustomer(dateRange: [Dayjs, Dayjs]) {
    const [salonActive] = useRecoilState(salonActiveState);
    const [salons] = useRecoilState(salonsState);

    if(salonActive) {
        const queriesBookingTotalNewCustomerSalonActive = salonActive?.salonBranches?.map((branch) => ({
            requestMeta: getBookingTotalNewCustomer,
            requestData: {
                query: {
                    startDate: formatDateTime(dateRange[0]),
                    endDate: formatDateTime(dateRange[1]),
                    salonId: salonActive.id,
                    salonBranchId: branch.id
                }
            }
        }))

        return queriesBookingTotalNewCustomerSalonActive ?? [];
    }

    const queriesBookingTotalNewCustomerAllSalons = salons?.map((salon) => {
        return {
            requestMeta: getBookingTotalNewCustomer,
            requestData: {
                query: {
                    startDate: formatDateTime(dateRange[0]),
                    endDate: formatDateTime(dateRange[1]),
                    salonId: salon.id
                }
            }
        }
    }).flat() ?? [];

    return queriesBookingTotalNewCustomerAllSalons;
}
