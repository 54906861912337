import './MainLayout.scss';

import { Layout, Menu } from 'antd';
import {
    ShopOutlined,
    // KeyOutlined,
    BarChartOutlined,
    // UserOutlined,
    LogoutOutlined
} from '@ant-design/icons';

import Sider from 'antd/es/layout/Sider';
import { Link, Outlet } from 'react-router-dom';
import { useLogout } from '../../hooks';
import './MainLayout.scss';
import { AppButton } from '../../components/buttons';
import { FlexBox } from '../../components/layouts';

export function MainLayout() {
    const logout = useLogout();


    const items = [
        { label: 'Báo cáo', key: '1', icon: <BarChartOutlined />, url: '/report' },
        { label: 'Salon', key: '2', icon: <ShopOutlined />, url: '/salon' },
        // { label: 'Tài khoản', key: '3', icon: <UserOutlined />, url: '/account' },
        // { label: 'Phân quyền', key: '4', icon: <KeyOutlined />, url: '/authorization' },
    ];


    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider style={{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0 }}
            >
                <FlexBox direction='column'>
                    <FlexBox direction='column'>
                        <img style={{ maxWidth: 150, height: 'auto', maxHeight: 170, margin: 20 }} src="https://my.easysalon.vn/static/assets/logo/logo-my.png" alt="logo" />
                        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                            {items.map(item => (
                                <Menu.Item key={item.key} icon={item.icon}>
                                    <Link to={item.url}>{item.label}</Link>
                                </Menu.Item>
                            ))}
                        </Menu>
                    </FlexBox>
                    <AppButton type='text' className="logout-button-container" onClick={logout} icon={<LogoutOutlined />}>Đăng xuất</AppButton>
                </FlexBox>
            </Sider>
            <Layout style={{ marginLeft: 200 }}>
                <Outlet />
            </Layout>
        </Layout>
    );
}
