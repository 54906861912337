import { getBookingOverviewChart } from "@/api/report";
import { salonActiveState, salonsState } from "@/components/state";
import { ViewType } from "@/shared/enum/chartEnum";
import { formatDateTime } from "@/utils";
import { Dayjs } from "dayjs";
import { useMemo } from "react";
import { useRecoilState } from "recoil";

export function useQueriesBookingChart(dateRange: [Dayjs, Dayjs]) {
    const [salonActive] = useRecoilState(salonActiveState);
    const [salons] = useRecoilState(salonsState);

    const viewType = useMemo(() => {
        const isStartYear = dateRange[0].startOf('year').isSame(dateRange[0]);
        const isEndYear = dateRange[1].endOf('year').isSame(dateRange[1]) && dateRange[1].isSame(dateRange[0], 'year');

        const isOnlyOneDay = dateRange[0].isSame(dateRange[1], 'day');

        if(isOnlyOneDay) {
            return ViewType.Hour;
        }

        if(isStartYear && isEndYear) {
            return ViewType.Month;
        }

        return ViewType.Day;
    }, [dateRange]);

    if(salonActive)
    {
        const queriesOverviewChartSalonActive = salonActive?.salonBranches?.map((branch) => ({
            requestMeta: getBookingOverviewChart,
            requestData: {
                query: {
                    startDate: formatDateTime(dateRange[0]),
                    endDate: formatDateTime(dateRange[1]),
                    salonId: salonActive.id,
                    salonBranchId: branch.id,
                    viewType: viewType,
                }
            }
        }));

        return queriesOverviewChartSalonActive ?? [];
    }

    const queriesOverviewChartAllSalon = salons?.map((salon) => ({
        requestMeta: getBookingOverviewChart,
        requestData: {
            query: {
                startDate: formatDateTime(dateRange[0]),
                endDate: formatDateTime(dateRange[1]),
                salonId: salon.id,
                viewType: viewType,
            }
        }
    }));

    return queriesOverviewChartAllSalon ?? [];
}
