import { useRecoilState } from "recoil";
import { Salon, salonActiveState, salonsState } from "../../components/state/index.ts";
import { Block } from "../../components/layouts/index.ts";
import { useCallback, useEffect, useState } from "react";
import { Tabs, TabsProps } from "antd";
import './report.scss';
import { Dayjs } from "dayjs";
import { HeaderReport } from "./@share/HeaderReport.tsx";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { dateHelper } from "@/helpers/dateHelper.ts";

function Report() {
    const dateRangeFromQueryParams = dateHelper.getDateRangeFromQueryParams();
    const salonIdFromQueryParams = Number(new URLSearchParams(window.location.search).get('salonId'));

    const location = useLocation();
    const navigate = useNavigate();

    const [salons] = useRecoilState(salonsState);
    const [salonActive, setSalonActive] = useRecoilState(salonActiveState);
    const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>(dateRangeFromQueryParams);

    useEffect(() => {
        if (salonIdFromQueryParams) {
            const salon = salons?.find(salon => salon.id === salonIdFromQueryParams);
            setSalonActive(salon ?? null);
        }
    }, [salonIdFromQueryParams, setSalonActive, salons]);

    const handleChangeDate = useCallback((dateRange: [Dayjs, Dayjs]) => {
		const queryParams = new URLSearchParams(window.location.search);
		queryParams.set('dateRange', dateRange.map((dayjs) => dayjs.toISOString()).join(','));
		window.history.replaceState(
			null,
			'',
			`${window.location.pathname}?${queryParams.toString()}`
		);
        setDateRange(dateRange);
    },[]);

    const handleChangeSalon = useCallback((salon?: Salon | null) => {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set('salonId', salon?.id.toString() ?? '');
        window.history.replaceState(
            null,
            '',
            `${window.location.pathname}?${queryParams.toString()}`
        );

        setSalonActive(salon ?? null);
    },[setSalonActive]);

    const handleTabChange = useCallback((key: string) => {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set('salonId', salonActive?.id.toString() ?? '');
        queryParams.set('dateRange', dateRange.map(dayjs => dayjs.toISOString()).join(','));
        navigate(`${key}?${queryParams.toString()}`);
    },[dateRange, salonActive, navigate]);
    
    const tabItems: TabsProps['items'] = [
        {
            key: '/report/revenue',
            label: 'Doanh thu',
        },
        {
            key: '/report/customer',
            label: 'Khách hàng',
        },
        {
            key: '/report/booking',
            label: 'Lịch hẹn',
        },
        // {
        //     key: '/report/warehouse',
        //     label: 'Kho hàng',
        // },
    ];

    return (
        <Block>
            <HeaderReport
                defaultValue={salonActive?.id} 
                dateRange={dateRange} 
                onChangeDate={handleChangeDate} 
                onChangeSalon={handleChangeSalon}
                salonActive={salonActive}
            />
            <Tabs 
                defaultActiveKey={location.pathname} 
                activeKey={location.pathname}
                items={tabItems} 
                onChange={handleTabChange} 
                destroyInactiveTabPane
            />
            <Outlet context={{ dateRange }} />
        </Block >
    )
}
export default Report