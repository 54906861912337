import { FormBase } from "@components/base";
import { Block, FlexBox, Spacer } from "@components/layouts";
import { Header } from "@components/typo";
import ReportRevenueOverview from "./ReportRevenueOverview";
import ReportRevenueChart from "./ReportRevenueChart";
import ReportRevenueTabs from "./ReportRevenueTabs";
import { useMemo } from "react";
import { TypeRevenueOverview } from "@/shared/enum";
import { salonActiveState, salonsState } from "@/components/state";
import { useRecoilState } from "recoil";
import { useHttpQueries } from "@/hooks";
import { chartHelper } from "@/helpers";
import { getRevenueOverview } from "@/api";
import { Dayjs } from "dayjs";
import { useOutletContext } from "react-router-dom";

export function RevenueReport() {
    const [salonActive] = useRecoilState(salonActiveState);
    const [salons] = useRecoilState(salonsState);

    const { dateRange } = useOutletContext<{ dateRange: [Dayjs, Dayjs] }>();

    const viewType = chartHelper.getViewTypeChart(dateRange);

    const allQueries = useMemo(() => {
        const objectTypes = [
            TypeRevenueOverview.All, TypeRevenueOverview.Service, TypeRevenueOverview.Product, TypeRevenueOverview.Package,
            TypeRevenueOverview.CashCard, TypeRevenueOverview.Debit, TypeRevenueOverview.Discount, TypeRevenueOverview.Booking, 
        ];

        if(salonActive){
            const queriesOverviewSalonActive = salonActive.salonBranches?.map((branch) => {
                return objectTypes.map((type) => {
                    return {
                        requestMeta: getRevenueOverview,
                        requestData: {
                            query: {
                                startDate: dateRange[0].format('YYYY-MM-DD HH:mm:ss'),
                                endDate: dateRange[1].format('YYYY-MM-DD HH:mm:ss'),
                                typeGetOverview: type,
                                salonId: salonActive.id,
                                salonBranchId: branch.id
                            }
                        }
                    }
                });
            }).flat() ?? [];

            return queriesOverviewSalonActive;
        }

        const queriesOverviewAllSalons = salons?.map((salon) => {
            return objectTypes.map((type) => {
                return {
                    requestMeta: getRevenueOverview,
                    requestData: {
                        query: {
                            startDate: dateRange[0].format('YYYY-MM-DD HH:mm:ss'),
                            endDate: dateRange[1].format('YYYY-MM-DD HH:mm:ss'),
                            typeGetOverview: type,
                            salonId: salon.id
                        }
                    }
                }
            });
        }).flat() ?? [];

        return queriesOverviewAllSalons;
    },[salonActive, salons, dateRange]);

    const revenueOverview =  useHttpQueries(allQueries);

    return (
        <FormBase onSubmit={() => { }}>
            <Block backgroundColor='white' className="tab-content">
                <FlexBox direction='column'>
                    <Spacer bottom={16}>
                        <Header level={3}>
                            Báo cáo doanh thu
                        </Header>
                    </Spacer>
                    <ReportRevenueOverview dateRange={dateRange} revenueOverview={revenueOverview}/>
                    {typeof viewType !== 'undefined' && <ReportRevenueChart dateRange={dateRange}/>}
                    <ReportRevenueTabs dateRange={dateRange} revenueOverview={revenueOverview} />
                </FlexBox>
            </Block>
        </FormBase>
    )
}
