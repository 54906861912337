import { DualAxes } from '@ant-design/plots';
import { Dayjs } from 'dayjs';
import { useCallback, useMemo } from 'react';
import { salonActiveState, salonsState } from '@/components/state';
import { useRecoilState } from 'recoil';
import { chartHelper } from '@/helpers';
import { ItemChart } from '../ReportRevenueChart';
import { GetRevenueOverviewChartResponseItem } from '@/api';
interface RevenueColumnChartProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly revenueData: (GetRevenueOverviewChartResponseItem[] | undefined)[];
    readonly isLoading: boolean;
}

export default function RevenueColumnChart(props: RevenueColumnChartProps) {
    const { dateRange, isLoading, revenueData } = props;
    const viewType = chartHelper.getViewTypeChart(dateRange);
    const [salonActive] = useRecoilState(salonActiveState);
    const [salons] = useRecoilState(salonsState);

    const getChildrenColumnChart = useCallback((date: string) => {
        const totalRevenue = revenueData?.reduce((acc, curr) => {
            return acc + (curr?.find((revenue) => revenue.date === date)?.totalRevenue ?? 0);
        }, 0);

        let children = revenueData?.map((revenueSalon) => {
            let nameItem = salons?.find((salon) => salon.id === revenueSalon?.[0]?.salonId)?.name
            const value = revenueSalon?.find((revenue) => revenue.date === date)?.totalRevenue ?? 0;

            if(salonActive) {
                nameItem = salonActive?.salonBranches?.find((branch) => branch.id === revenueSalon?.[0]?.salonBranchId)?.name
            }

            return {
                name: nameItem,
                value: value,
                percent: (value / totalRevenue) * 100
            }
        }) as ItemChart["children"] ?? [];

        children = [...children, {
            name: "Tổng doanh thu",
            value: totalRevenue,
            percent: undefined
        }]

        return children;
    }, [revenueData, salons, salonActive]);

    const dataColumnChart = useMemo(() => {
        const groupData = revenueData?.[0]?.map((item, index) => {
                const totalRevenue = revenueData?.reduce((acc, curr) => {
                    return acc + (curr?.[index]?.totalRevenue ?? 0);
                }, 0);
    
                return {
                    date: chartHelper.getLabelDateByViewChart(viewType, item.date),
                    value: totalRevenue,
                    children: getChildrenColumnChart(item.date)
                }
            });

        return groupData;
    }, [getChildrenColumnChart, revenueData, viewType]);

    const configColumnChart = useMemo(()=> (
        {
            data: dataColumnChart,
            xField: 'date',
            legend: false,
            scrollbar: { x: { ratio: 0.5 } },
            axis: {
                y: {
                    labelFormatter: (v: unknown) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
                },
            },
            interaction: {
                tooltip: {
                render: (_: React.ReactNode, { title }: { title: string}) => {
                    const data = dataColumnChart?.find((item) => item.date === title);

                    return (
                        `<div style="padding: 10px 20px; width: 300px; ">
                            <div style="margin-bottom: 10px">
                                <span style="color: #000">${title}</span>
                            </div>
                            <div style="margin-bottom: 8px">
                                <ul style="margin-left: 0px">
                                    ${data?.children?.map((child) => {
                                        return `
                                            <li style="margin-bottom: 5px; color: blue">
                                                <div style="display: flex; justify-content: space-between">
                                                    <span style="color: #000; font-weight: 500">${child.name}: </span>
                                                    <div>
                                                        <span style="color: #8F9BB2">${child.percent ? `(${child.percent.toFixed(2)}%)`:'' }</span>
                                                        <span style="color: #0A1F44; font-weight: 500">${child.value.toLocaleString('en-US')}</span>
                                                    </div>
                                                </div>
                                            </li>
                                        `
                                    }).join('')}
                                </ul>
                            </div>
                        `
                    )},
                },
            },
            children: [
                {
                    type: 'interval',
                    yField: 'value',
                    style: {
                        maxWidth: 40,
                        radiusTopLeft: 10,
                        radiusTopRight: 10,
                    },
                    color: '#2970FF',
                },
            ],
        }
    ), [dataColumnChart]);

    return (<DualAxes loading={isLoading} {...configColumnChart} />);
}
